<template>
  <div class="filter-menu-dropdown card">
    <header class="card-header">
      <p class="card-header-title has-text-info">
        <span class="icon">
          <i class="fal fa-search" />
        </span>
        <span>Options keyword</span>
      </p>
      <a class="card-header-icon" aria-label="close" @click="clearMenu">
        Close
      </a>
    </header>
    <hr class="hr is-marginless" />
    <div class="card-content">
      <similar-listings-keyword />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'SimilarListingsKeywordFilter',
  components: {
    'similar-listings-keyword': () => import('../SimilarListingsKeyword')
  },
  methods: {
    ...mapMutations({
      clearMenu: 'companion/similarListings/filterBy/clearMenu'
    })
  }
}
</script>
